import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { reserveButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://www.opentable.ca/r/the-hide-out-diner-vancouver?corrid=8a2cb756-f938-4dae-b8a0-31e93b731959&p=2&sd=2024-01-22T22%3A30%3A00`}
              target="_blank"
            >
              <CFView hover>
                <CFImage src={reserveButton} maxWidth="77vw" alt="About" />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://www.opentable.ca/r/the-hide-out-diner-vancouver?corrid=8a2cb756-f938-4dae-b8a0-31e93b731959&p=2&sd=2024-01-22T22%3A30%3A00`}
              target="_blank"
            >
              <CFView hover>
                <CFImage src={reserveButton} maxHeight="7vh" alt="About" />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
